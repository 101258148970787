<template>
  <div>
    <JobRenewContractProfileCard :loading="loading" @setLoading="loading = $event"/>
    <OfferLinesCard />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JobRenewContractProfileCard from '@/components/jobs/card/JobRenewContractProfileCard.vue'
import OfferLinesCard from '@/components/offers/card/OfferLinesCard.vue'

export default {
  components: {
    OfferLinesCard,
    JobRenewContractProfileCard
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('offer', {
      offer: 'getOffer',
    }),
  },
  methods: {
    ...mapActions('offer', ['loadOffer']),
    async loadOfferData() {
      try {
        this.loading = true
        await this.loadOffer(this.$route.params.id)
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.loadOfferData()
  },
}
</script>